<!-- 商超预警 -->
<template>
  <div>
    <div class="globle_border">
      <div class="search-box">
        <div class="input-box">
          <el-input v-model="barCode" @change="onScreenData" placeholder="搜索商品条形码"></el-input>
        </div>
        <div class="input-box">
          <el-input v-model="supplierName" @change="onScreenData" placeholder="搜索供应商名称"></el-input>
        </div>
        <div class="input-box">
          <el-input v-model="productName" @change="onScreenData" placeholder="搜索商品名称"></el-input>
        </div>
        <!-- <el-button type="primary" @click='dialogVisible = true'>新增</el-button> -->
      </div>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" v-loading="dataLoding">
          <el-table-column prop="number" label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="supplierName" label="供应商名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="shopName" label="商超名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="品类"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="enterBox" label="入箱数"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
          <el-table-column min-width="160" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="inventoryCount" label="库存数量"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="supplyPrice" label="供货价(元)"></el-table-column>
          <!-- <el-table-column min-width="150" show-overflow-tooltip prop="deliveryNum" label="发货单号"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="productDate" label="生产日期"></el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip label="保质期(天)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="expirationDate" label="剩余保质期">
            <template slot-scope="scope">
              <span style="color: #eb2500" v-if="scope.row.expirationDate <= 0">0天</span>
              <span v-else>{{ scope.row.expirationDate }}天</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="warningType" label="预警类型">
            <template slot-scope="scope">
              {{ getWarningTypeName(scope.row.warningType) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="200" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" plain @click="handleDelete(scope.row)">取消预警</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    <!-- 编辑 -->
    <el-dialog :title="isAdd ? '修改' : '新增'" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" ref="ruleForm" label-width="150px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="productName">
              <el-input v-model="ruleForm.productName" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="规格" prop="specs">
              <el-input v-model="ruleForm.specs" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="厂家" prop="factoryName">
              <el-input v-model="ruleForm.factoryName" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分类名称" prop="categoryId">
              <el-cascader v-model="ruleForm.categoryId" :options="typeOptions" :show-all-levels="false"
                :props="{ label: 'categoryName', value: 'categoryId', emitPath: false }"></el-cascader>
            </el-form-item>
            <el-form-item label="品牌" prop="brand">
              <el-input v-model="ruleForm.brand" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "shelfLifeWarning",
  data() {
    return {
      dataLoding: false,
      expirationDate: "",
      supplierName: "",
      productName: "",
      barCode: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      // 弹窗
      isAdd: false,
      dialogVisible: false,
      typeOptions: [],
      ruleForm: { productName: "", specs: "", categoryId: "" },
      rules: {
        productName: [{ required: true, message: "请输入商品名称", trigger: "change" }],
        specs: [{ required: true, message: "请输入规格", trigger: "submit" }],
        categoryId: [{ required: true, message: "请选择分类", trigger: "change" }],
        brand: [{ required: true, message: "请输入品牌", trigger: "change" }],
        factoryName: [{ required: true, message: "请输入厂家", trigger: "change" }],
      },
      submitLoding: false,
    }
  },
  created() {
    this.getShopProductWarnList()
    // this.getTypeOptions();
  },
  methods: {
    getShopProductWarnList() {
      this.dataLoding = true
      this.$axios
        .get(this.$api.getShopProductWarnList, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            // auditStatus: 1,
            supplierName: this.supplierName || null,
            barCode: this.barCode || null,
            productName: this.productName || null,
            // expirationDate: this.expirationDate || null,
          },
        })
        .then(res => {
          this.dataLoding = false
          this.tableData = res.data.result.list.map(t => ({
            ...t,
            expirationDate: t.expirationDate < 0 ? 0 : t.expirationDate,
          }))
          this.totalItemsCount = res.data.result.totalCount
        })
    },
    getWarningTypeName(warningType) {
      if (warningType == 1) {
        return "第一次预警"
      } else if (warningType == 2) {
        return "第二次预警"
      } else {
        // 可以根据需要处理其他情况，比如未知的预警类型
        return " "
      }
    },
    getTypeOptions() {
      this.$axios
        .get(this.$api.categoryList, {
          params: {
            auditStatus: 1,
            page: 1,
            pageSize: 200,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            let list = res.data.result.list
            list.forEach(t => {
              if (t.children.length == 0) t.children = null
            })
            this.typeOptions = list
          }
        })
    },
    handleEdit(row) {
      let { productSupplierId, categoryId, productName, specs, brand, factoryName } = row
      this.ruleForm = {
        productSupplierId,
        categoryId,
        productName,
        specs,
        brand,
        factoryName,
      }
      this.dialogVisible = true
      this.isAdd = true
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) return console.error("表单必填项")
        this.submitLoding = true
        let params = Object.assign({}, this.ruleForm)
        let url = params.productSupplierId ? "productPut" : "productAdd"
        let msg = params.productSupplierId ? "修改" : "新增"
        let method = params.productSupplierId ? "put" : "post"
        this.$axios[method](this.$api[url], params).then(res => {
          this.submitLoding = false
          if (res.data.code == 100) {
            this.$message.success(`${msg}成功`)
            if (params.productSupplierId) {
              this.tableData = this.tableData.filter(t => t.productSupplierId != params.productSupplierId)
            }
            this.onCancel()
          }
        })
      })
    },
    onCancel() {
      this.$refs["ruleForm"].resetFields()
      this.dialogVisible = false
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.onScreenData()
    },
    // 换页
    handleCurrentChange() {
      this.page += 1
      this.tableData = []
      this.getShopProductWarnList()
    },
    // 筛选
    onScreenData() {
      this.currentPage = 1
      this.tableData = []
      this.getShopProductWarnList()
    },
    // 删除
    handleDelete(row) {
      this.$confirm("确定取消这条预警？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          return this.$axios.post(this.$api.deliveryProductDeleteShopProductWarn, {
            shopInventoryInfoId: row.shopInventoryInfoId,
          })
        })
        .then(res => {
          if (res.data.code == 100) {
            this.$message.success("删除成功")
            // this.tableData = this.tableData.filter((t) => t.id != row.id);
            location.reload()
            this.getShopProductWarnList()
          } else {
            this.$message.error("删除失败")
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.search-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;

  .input-box {
    margin-left: 20px;
  }
}
</style>
